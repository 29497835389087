export enum TAP_USER_ROLES {
  INDIVIDUAL_USER = "INDIVIDUAL_USER",
  CORPORATE_USER = "CORPORATE_USER",
}

export enum INDIVIDUAL_USER_TYPE {
  INDIVIDUAL_EDGE_LIGHT = "Edge Lite",
  INDIVIDUAL_EDGE_PREMIUM = "Edge Premium",
}

export enum CORPORATE_USER_TYPE {
  ADMIN = "ADMIN",
  HR = "HR",
  EMPLOYEE = "EMPLOYEE",
}

export enum CORPORATE_TEAMS_USER_TYPE {
  MANAGER = "MANAGER",
  TEAM_LEADER = "TEAM_LEADER",
  MEMBER = "MEMBER",
}
