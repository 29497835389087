import { useEffect } from "react";
import { UserDetailInitialState } from "../core/models/types/userDetail.types";
import { useLocation, useNavigate } from "react-router-dom";
import { CORPORATE_USER_TYPE, TAP_USER_ROLES } from "../core/models/enums/tap.enum";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MORE_MENU_OPTIONS } from "../features/layouts/presentation/ECHeader/menu.helper";
import { useValidateManageTeamLink } from "../features/Teams/hooks/teams.hook";

type Props = {
  allowedToIndividualUser: boolean,
  allowedToCorporateUser: boolean,
  allowedRoles: any;
  pageURL?: string;
  children: JSX.Element;
};

const ProtectRoute = (props: Props) => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { allowedToIndividualUser, allowedToCorporateUser, allowedRoles, pageURL, children } = props;
  const { data: isEligibleForManageTeam } = useValidateManageTeamLink({ token: token ?? "" });
  const userDetails: Partial<UserDetailInitialState> = useSelector(
    (state: RootState) => state.userDetail
  );
  const { role, isAdmin, isHR, isEmployed, } = userDetails

  const location = useLocation();
  const navigate = useNavigate();

  const isAuthorized = () => {
    if (role === TAP_USER_ROLES.INDIVIDUAL_USER) {
      if (allowedToIndividualUser) {
        return true;
      } else {
        return false;
      }

    } else if (role === TAP_USER_ROLES.CORPORATE_USER) {
      if (allowedToCorporateUser) {
        if (allowedRoles.includes(CORPORATE_USER_TYPE.EMPLOYEE)) {

          if (isHR || isAdmin || isEmployed) {
            if (pageURL && pageURL === MORE_MENU_OPTIONS.MANAGE_TEAMS && !isAdmin && !isHR) {
              return isEligibleForManageTeam;
            } else {
              return true;
            }
          } else {
            return false
          }
        }

        if (allowedRoles.includes(CORPORATE_USER_TYPE.HR)) {
          if (isHR || isAdmin) {
            return true;
          } else {
            return false
          }
        }

        if (allowedRoles.includes(CORPORATE_USER_TYPE.ADMIN)) {
          if (isAdmin) {
            return true;
          } else {
            return false
          }
        }

        return true

      } else {
        return false
      }

    } else {
      return false
    }
  };


  useEffect(() => {
    if (!isAuthorized()) {
      navigate('/');
    }
  }, [userDetails, location]);

  return children;
};

export default ProtectRoute;
